<template>
<div>
  <div class="page p-top-header p-bottom-xl-1 page-dark page-min-height">
    <div class="page__wrap row-4-desk" data-animate="page-wrap">
      <div class="page-contacts__wrap">
       <div>
        <h1 class="title-base-mix-big m-bottom-m-1">Contacts</h1>
        <p class="text-m-1">Università degli Studi di Padova &<br>
Veneto Institute of Oncology IOV-IRCCCS<br>
        via Gattamelata 64 <br>
        35128 Padova PD Italy</p>
        <p class="text-m-1"><a href="tel:+390498215891"> 39 049 8215891</a></p>
       </div>
       <div class="page-contacts__col-form">
         <PagesContactsTwoTabs />
       
       </div>
      </div>
    </div>
   
  </div>
  <FooterMain />
  </div>
</template>

<script setup>
import queries from '@/graphql/dato/queries/index.js'
//import pageTransition from '@/assets/js/transitions/helper.js'

definePageMeta({
  layout: 'default',
  //pageTransition,
})

const { locale, t } = useI18n()
const route = useRoute()
const lightTheme = setLightTheme()

const query = queries.home.getHome
const variables = computed(() => ({
  locale: locale.value,
}))

const { data } = await useGraphql(query, variables)
/*const query = queries.corporates.getContactsSplashPage
const variables = computed(() => ({
  locale: locale.value,
}))

const { data } = await useGraphql(query, variables)

if (!data.value?.contactSplashPage) {
  //throw createError({ statusCode: 404 })
}*/

onMounted(() => {
  lightTheme.value = true
  document.body.classList?.add('--light-theme')
  setTimeout(() => {
    lightTheme.value = true
    document.body.classList?.add('--light-theme')
  }, 300)
})

//const page = computed(() => data.value?.contactSplashPage)
//const seo = computed(() => data.value?.contactSplashPage?.seo || {})
//const localeParams = computed(() => project.value?._allSlugLocales || [])

const seo = computed(() => {
  return {
    title:
      t('contacts.seo.title'),
      description: t('contacts.seo.description')
  }
})

// Seo
useSeo({
  ...seo.value,
  //localeParams: useHelpers().generateSeoLocaleParams(localeParams, 'slug'),
})

onUnmounted(() => {
  //console.log('on unmounted: ', isInView.value, lightTheme.value)
  document.body.classList?.remove('--light-theme')
  lightTheme.value = false
})

/*watch(
  () => route.path,
  () => {
    

    if (
      route?.name?.includes('contacts') || route?.name?.includes('index') ||
      route?.name?.includes('lets-talk')
    ) {
      document.body.classList?.add('--light-theme')
      lightTheme.value = true
    } else {
      document.body.classList?.remove('--light-theme')
      lightTheme.value = false
    }
  }
)*/
</script>
