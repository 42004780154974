<template>
  <!-- Form -->
  <div class="position-form" :class="{ '--loading': loading }">
    <FormKit
      type="form"
      ref="formPos"
      :actions="false"
      @submit="onSubmit"
      class="jobs-form"
    >
      <div class="form-row">
        <!-- Nome -->
        <FormKit
          type="text"
          name="nome"
          :label="$t('contacts.form.name')"
          validation="required"
          :placeholder="$t('contacts.form.name')"
        />
      </div>

      <div class="form-row">
        <!-- Cognome -->
        <FormKit
          type="text"
          name="cognome"
          :label="$t('contacts.form.surname')"
          validation="required"
          :placeholder="$t('contacts.form.surname')"
        />
      </div>

      <div class="form-row flex">
        <!-- Email -->
        <FormKit
          type="email"
          name="email"
          :label="$t('contacts.form.email')"
          validation="required|email"
          :placeholder="$t('footer.main.emailField')"
        />
      </div>

      <div class="form-row">
        <!-- Telefono -->
        <FormKit
          type="text"
          name="telefono"
          :label="$t('contacts.form.phone')"
          :placeholder="$t('contacts.form.phone')"
        />
      </div>

      <div class="form-row">
        <!-- Messaggio -->
        <FormKit
          type="textarea"
          name="message"
          :label="$t('contacts.form.message')"
          rows="6"
          validation="required"
        />
      </div>

      <div class="form-row">
        <!-- CV File Upload -->
        <FormKit
          type="file"
          :label="$t('contacts.form.fileUpload')"
          accept=".pdf"
          name="documents"
          :wrapper-class="'m-bottom-48px m-top-24px'"
        >
          <template #noFiles>
            <span
              class="formkit-no-files"
              v-html="$t('contacts.form.fileUploadTxt')"
            />
          </template>
        </FormKit>
      </div>

      <div class="form-row">
        <!-- GDPR Check -->
        <div>
          <FormKit
            type="checkbox"
            :label="$t('contacts.form.privacyCaption')"
            name="gdpr"
            validation="accepted"
            :validation-messages="{
              accepted: $t('contacts.form.privacyError'),
            }"
          >
            <template #label="context">
              <div class="gdpr-checkbox-text" v-html="context.label" />
            </template>
          </FormKit>
        </div>
      </div>

      <!-- Submit button -->
      <div class="m-top-48px position-form-cta">
        <button
          :disabled="loading"
          class="button-rect-1 is-big"
          @click="onSubmitClick"
        >
          <span class="is-text-uppercase">{{
            $t('contacts.careers.formCTA')
          }}</span>
        </button>
      </div>
    </FormKit>
  </div>
</template>

<script>
export default {
  name: 'PositionForm',
}
</script>

<script setup>
import { load } from 'recaptcha-v3'
import { reset } from '@formkit/core'

const emit = defineEmits(['careers:success', 'careers:failure'])
const router = useRouter()
const localePath = useLocalePath()
const { t } = useI18n()

const formPos = ref(null)
const loading = ref(false)
const postin_api_id = t('contacts.form.formCareers.postinApiId')

const props = defineProps({
  career: String,
})

const onSubmitClick = () => {
  // retrieve the core node (several ways to do this):
  const node = formPos.value.node
  // submit the form!
  node.submit()
}

const onSubmit = (formkitData, node) => {
  node.clearErrors()

  loading.value = true

  load('6Lcyu5YqAAAAAGaD6DGNpdzymkxMw9Lqgh1rAr1m').then((recaptcha) => {
    recaptcha.hideBadge()
    recaptcha
      .execute('postin')
      .then((token) => {
        // const lang = locale.value
        const formData = new FormData()
        formData.append('captcha', token)
        formData.append('nome', formkitData.nome)
        formData.append('cognome', formkitData.cognome)
        formData.append('email', formkitData.email)
        formData.append('telefono', formkitData.telefono)
        formData.append('message', formkitData.message)
        if (
          formkitData.documents &&
          formkitData.documents[0] &&
          formkitData.documents[0].file
        ) {
          formData.append(
            'documents',
            formkitData.documents[0].file,
            formkitData.documents[0].name
          )
        }

        //formData.append('gdpr', formkitData.gdpr)
        //formData.append('newsletter', formkitData.newsletter)
        // ID del template mail di postin
        formData.append('api_id', postin_api_id)

        fetch('https://postin.quammbase.it/emails/', {
          method: 'POST',
          body: formData,
        })
          .then(function (response) {
            loading.value = false

            if (response.status === 200) {
              console.log(response)
              emit('careers:success')
              reset(node)
            } else {
              console.log(response)
              emit('careers:failure', 'careers')
            }
          })
          .catch(function (error) {
            console.log(error)
            loading.value = false
            emit('careers:failure', 'careers')
          })
      })
      .catch(function (error) {
        console.log(error)
        loading.value = false
        emit('careers:failure', 'careers')
      })
  })
}

const matchMedia = useHelpers().evalMatchMedia('(min-width: 1000px)')
</script>

<style lang="scss">
@import './style.scss';
</style>
