<template>
  <div class="pages-contacts-two-tabs">
    <div ref="resultCont" class="pages-contacts__result-cont">
      <div v-if="isSuccess === true">
        <p class="title-base-mix-big m-bottom-m-1">Thank you!</p>
        <p class="text-m-1">
          We’ve received your message and will follow up shortly. In the
          meantime, continue to discover our site.
        </p>
        <div class="pages-contacts__result-cont__link-wrap">
          <NuxtLink
            :to="localePath({ name: 'index' })"
            :external="true"
            class="button-rect-1 is-big"
          >
            <span class="is-text-uppercase">HOMEPAGE</span>
          </NuxtLink>
        </div>
      </div>

      <div v-else>
        <p class="title-base-mix-big m-bottom-m-1">
          Oh no! <br />
          Something went wrong
        </p>
        <p class="text-m-1">There was an error tryng to send your message.</p>
        <div class="pages-contacts__result-cont__link-wrap">
          <button @click="restoreForm" class="button-rect-1 is-big">
            <span class="is-text-uppercase">Try again</span>
          </button>
        </div>
      </div>
    </div>
    <div ref="formContainer">
      <div class="pages-contacts-two-tabs__inner m-bottom-40px">
        <div class="pages-contacts-two-tabs__tap-bar">
          <button
            key="0"
            @click="handleTabClick(0, 'book')"
            :class="{ '--active': currentTab === 0 }"
            class="pages-contacts-two-tabs__button"
          >
            <span class="text-m-1 is-text-uppercase">Information</span>
          </button>
          <button
            key="1"
            @click="handleTabClick(1, 'info')"
            :class="{ '--active': currentTab === 1 }"
            class="pages-contacts-two-tabs__button"
          >
            <span class="text-m-1 is-text-uppercase">Work with us</span>
          </button>
        </div>
      </div>

      <div ref="tabsContainer">
        <PagesContactsRequestForm
          ref="contacts"
          v-if="currentTab === 0"
          @contacts:success="handleFormSuccess"
          @contacts:failure="handleFormFailure"
        />
        <PagesContactsPositionForm
          ref="careers"
          v-if="currentTab === 1"
          @careers:success="handleFormSuccess"
          @careers:failure="handleFormFailure"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PagesContactsTwoTabs',
}
</script>

<script setup>
import { gsap } from 'gsap'

const currentTab = ref(0)
const tabsContainer = ref(null)
const formContainer = ref(null)
const contacts = ref(null)
const careers = ref(null)
const resultCont = ref(null)

const isSuccess = ref(false)
const formFilled = ref(undefined)

const lenis = inject('lenis')

onMounted(() => {
  const result = resultCont.value
  //console.log('on mount')
  setTimeout(() => {
    gsap.to(result, { autoAlpha: 0 })
  }, 500)
})

const handleTabClick = (index, action) => {
  const newTab = index === 0 ? 0 : 1
  if (newTab !== currentTab.value) {
    animateSwitch(newTab)
  }
}

const animateSwitch = (newTab) => {
  // Anima l'uscita del componente attivo
  gsap.to(tabsContainer.value, {
    opacity: 0,
    duration: 0.15,
    onComplete: () => {
      currentTab.value = newTab // Cambia il componente
      // Anima l'entrata del nuovo componente
      gsap.fromTo(
        tabsContainer.value,
        { opacity: 0 },
        { opacity: 1, duration: 0.2 }
      )
    },
  })
}

const handleFormSuccess = () => {
  const parent = formContainer.value
  const result = resultCont.value


  gsap.to(parent, {
    autoAlpha: 0,
    duration: 0.15,
    onComplete: () => {
      isSuccess.value = true
      console.log('dentro oncomplete success')
      // Anima l'entrata del nuovo componente
      console.log(resultCont.value)
      gsap.fromTo(result, { autoAlpha: 0 }, { autoAlpha: 1, duration: 0.2 })
    },
  })
  lenis.value.scrollTo(0)
}

const handleFormFailure = (action) => {
  formFilled.value = action
  isSuccess.value = false
  const parent = formContainer.value
  const result = resultCont.value

  gsap.to(parent, {
    autoAlpha: 0,
    duration: 0.15,
    onComplete: () => {
      // Anima l'entrata del nuovo componente
      gsap.fromTo(result, { autoAlpha: 0 }, { autoAlpha: 1, duration: 0.2 })
    },
  })
  lenis.value.scrollTo(0)
}

const restoreForm = () => {
  if (formFilled.value === 'contacts') {
    currentTab.value = 0
  } else {
    currentTab.value = 1
  }

  const parent = formContainer.value
  const result = resultCont.value

  gsap.to(result, {
    autoAlpha: 0,
    duration: 0.15,
    onComplete: () => {
      // Anima l'entrata del nuovo componente
      gsap.fromTo(parent, { autoAlpha: 0 }, { autoAlpha: 1, duration: 0.2 })
    },
  })
}
</script>

<style lang="scss">
@import './style.scss';
</style>
